import request from "@/utils/request";

/**
 * 登录
 */
export async function login(data) {
  const res = await request.post("/user/login", data);
  if (res.data.code === 0) {
    // setToken(res.data.data.access_token, data.remember);
    return res.data.data;
  }
  return Promise.reject(new Error(res.data.message));
}

/**
 * 登录
 */
export async function loginQQ(data) {
  const res = await request.post("/oauth/render", data);
  if (res.data.code === 0) {
    // setToken(res.data.data.access_token, data.remember);
    return res.data.data;
  }
  return Promise.reject(new Error(res.data.message));
}
/**
 * 获取验证码
 */
export async function getCaptcha() {
  const res = await request.get("/user/getCaptcha");
  if (res.data.code === 0) {
    return res.data.data;
  }
  return Promise.reject(new Error(res.data.message));
}

/**
 * 注册用户
 */
export async function register(data) {
  const res = await request.post("/user/register", data);
  if (res.data.code === 0) {
    return res.data.message;
  }
  return Promise.reject(new Error(res.data.message));
}

/**
 * 找回用户
 */
export async function findUser(data) {
  const res = await request.post("/user/find", data);
  if (res.data.code === 0) {
    return res.data.message;
  }
  return Promise.reject(new Error(res.data.message));
}
/**
 * 重置密码
 */
export async function resetPwd(data) {
  const res = await request.post("/user/resetPassword", data);
  if (res.data.code === 0) {
    return res.data.message;
  }
  return Promise.reject(new Error(res.data.message));
}

/**
 * qq登录
 */
export async function qqLogin(data) {
  const res = await request.post("/user/qqLogin", data);
  if (res.data.code === 0) {
    return res.data.data;
  }
  return Promise.reject(new Error(res.data.message));
}

/**
 * qq绑定
 */

export async function qqBind(data) {
  const res = await request.post("/user/qqBind", data);
  if (res.data.code === 0) {
    return res.data.data;
  }
  return Promise.reject(new Error(res.data.message));
}
