<template>
  <div class="main-content-boxed" id="page-container">
    <main id="main-container">
      <div class="bg-image">
        <div class="row mx-0 bg-black-50">
          <div
            class="hero-static col-md-6 col-xl-8 d-none d-md-flex align-items-md-end"
          >
            <div class="p-4">
              <p class="fs-3 fw-semibold text-white">
                Get Inspired and Create.
              </p>
              <p class="text-white-75 fw-medium">
                Copyright © <span data-toggle="year-copy">2022</span>
              </p>
            </div>
          </div>
          <div
            class="hero-static col-md-6 col-xl-4 d-flex align-items-center bg-body-extra-light"
          >
            <div class="content content-full">
              <div class="px-4 py-2 mb-4">
                <a class="link-fx fw-bold" href="/index">
                  <i class="fa fa-fire"></i>
                  <span class="fs-4 text-body-color">宥马-Tool</span>
                </a>
                <h1 class="h3 fw-bold mt-4 mb-2">欢迎来到您的用户中心</h1>
                <h2 class="h5 fw-medium text-muted mb-0">请登录</h2>
              </div>
              <form class="js-validation-signin px-4" onsubmit="return false;">
                <div class="form-floating mb-4">
                  <input
                    v-model="form.username"
                    class="form-control"
                    id="username"
                    name="username"
                    placeholder="输入你的账号"
                    type="text"
                  />
                  <label class="form-label" for="username">账号</label>
                </div>
                <div class="form-floating mb-4">
                  <input
                    v-model="form.password"
                    class="form-control"
                    id="password"
                    name="password"
                    placeholder="输入你的密码"
                    type="password"
                  />
                  <label class="form-label" for="password">密码</label>
                </div>
                <div class="mb-4">
                  <div class="form-check">
                    <input
                      checked=""
                      class="form-check-input"
                      id="login-remember-me"
                      name="login-remember-me"
                      type="checkbox"
                      value=""
                    />
                    <label class="form-check-label" for="login-remember-me"
                      >记住我</label
                    >
                  </div>
                </div>
                <div class="mb-4">
                  <button
                    class="btn btn-lg btn-alt-primary fw-semibold"
                    @click="submit()"
                    type="submit"
                  >
                    登录
                  </button>

                  <div class="mt-4">
                    <a
                      class="fs-sm fw-medium link-fx text-muted me-2 mb-1 d-inline-block"
                      @click="qq()"
                    >
                      QQ登录
                    </a>
                    <router-link
                      class="fs-sm fw-medium link-fx text-muted me-2 mb-1 d-inline-block"
                      to="register"
                    >
                      创建账户
                    </router-link>
                    <router-link
                      class="fs-sm fw-medium link-fx text-muted me-2 mb-1 d-inline-block"
                      to="find"
                    >
                      忘记密码?
                    </router-link>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>
<script>
import { setRoutes } from "@/router";
import { login, loginQQ } from "@/api/login";
export default {
  name: "Login",
  data() {
    const defaultForm = {
      username: null,
      password: null,
    };
    return {
      form: {
        ...defaultForm,
      },
    };
  },

  methods: {
    submit() {
      if (this.form.username === null || this.form.password === null) {
        this.$message.warning("账号或密码不能为空");
        return;
      }

      login({
        username: this.form.username,
        password: this.form.password,
      })
        .then((res) => {
          localStorage.setItem("user", JSON.stringify(res)); // 存储用户信息到浏览器
          localStorage.setItem("menus", JSON.stringify(res.menus)); // 存储menus信息到浏览器
          // 动态设置当前用户的路由
          setRoutes();

          this.$message.success("登录成功");

          setTimeout(() => {
            window.location.href = "home";
          }, 1000);
        })
        .catch((e) => {
          this.$message.error(e.message);
        });
    },
    qq() {
      loginQQ()
        .then((res) => {
          console.log(res);

          window.location.href = res;
        })
        .catch((e) => {
          this.$message.error(e.message);
        });
    },
  },
};
</script>

<style>
@media screen and (max-width: 500px) {
  .el-message-box {
    width: 300px !important;
  }
}

@media screen and (max-width: 500px) {
  .el-message {
    min-width: 300px !important;
  }
}

@media screen and (max-width: 500px) {
  .el-dialog__wrapper .el-dialog {
    width: 300px !important;
  }

  .el-dialog__body {
    padding: 10px 20px !important;
  }

  .el-form-item__label {
    width: 68px !important;
  }

  .el-select,
  .el-input {
    width: 180px !important;
  }
}

.bg-image {
  background-image: url("/static/media/photos/photo34@2x.jpg");
}
</style>
